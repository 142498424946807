import React from "react"
import {
  ActivityBox,
  DiscussBox,
  DefinitionBox,
  CopyrightBox
} from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import worksheets from '../worksheets.svg';
import ritual1 from './ritual1.png';
import ritual2 from './ritual2.png';
import blessing from './blessing.png';
import { withPrefix } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { ActivitySheetButton } from "../../../components/button"

const WhatIsPilgrimage = () => (
  <Layout title="What is pilgrimage?">
    <SmallSectionHeader
      color={theme.colors.red.dark}
      image={headerImage}
      to="/pilgrimage"
    >
      Pilgrimage
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/pilgrimage', name: 'Pilgrimage' },
      { name: 'What is pilgrimage?' }
    ]} />
    <Page>
      <h1>What is pilgrimage?</h1>
      <p className="page-left">
        People have always wanted to see and experience the places they read about in their holy books.
      </p>
      <Figure
        caption="Pilgrims walking along ‘the Pilgrim’s Way’ towards Canterbury."
        className="page-right row-2"
      >
        <StaticImage
          src="./pilgrims.jpg"
          alt="Pilgrims Hiking"
        />
      </Figure>
      <p className="page-left">
        Canterbury Cathedral has been a major pilgrimage destination for many centuries and it was the murder of Archbishop Thomas Becket in 1170 and his subsequent canonisation in 1173 that made Canterbury Cathedral the third most important site of Christian pilgrimage in the world, after Jerusalem and Rome. Pilgrims continued to visit the Cathedral in great numbers until the English Reformation and the destruction of the shrine of St Thomas in 1538.
      </p>
      <ActivitySheetButton href={withPrefix('/Pilgrimage.pdf')} className="page-left" />
      <p className="page-left">
        In more recent times, pilgrimage has become increasingly popular and today Canterbury Cathedral welcomes thousands of people both setting out on and finishing their journey of pilgrimage.
      </p>
      <DefinitionBox title="Pilgrim" className="page-right row-2">
        A person who journeys to a sacred place for religious reasons.
      </DefinitionBox>
      <p className="page-left">
        Pilgrimage is an outward expression of faith and many pilgrims say it helps them to feel closer to God. Of course, many people also enjoy getting to experience new people and places.
      </p>
      <DiscussBox>
        Where are the sacred sites of pilgrimage for different faiths? Why are they sacred?
      </DiscussBox>
      <h2>The Journey</h2>
      <p>
        All religions see the journey to arrive at the holy place as very important. Making the decision to go on a pilgrimage, then preparing and making plans are all key parts of the pilgrimage journey. Pilgrims must work out how to afford the trip, how they will travel and what route they will take. Often pilgrims travel in groups and make stops at other religious sites on the way to their destination.
      </p>
      <DiscussBox>Why do you think people go on pilgrimage?</DiscussBox>
      <h2>Rituals</h2>
      <p className="page-left">
        When pilgrims arrive at the holy site there are rituals they are expected to follow. For example, Muslims on pilgrimage to Mecca must wash and put on special clothes called Ihram before they arrive. Hindus visiting the holy River Ganges often end their pilgrimage by bathing in the river as an act of purification – the washing away of sins. Christian pilgrims visiting Canterbury Cathedral might choose to light a candle at one of Cathedral altars.
      </p>
      <StaticImage
        src="./ritual1.png"
        className="page-right"
        alt="Ritual"
      />
      <DefinitionBox title="Shrine" className="page-left">
        A place believed to be holy because of its association with a person or event.
      </DefinitionBox>
      <StaticImage
        src="./ritual2.png"
        className="page-right"
        alt="Ritual"
      />
      <DiscussBox>What do you think people would hope to see and do when they got to their pilgrimage destination?</DiscussBox>

      <p className="page-left">
        Today, many pilgrims to Canterbury Cathedral are able to let us know about their journey. They are offered a blessing before setting out and a thanks at the end of their journey. In most cases pilgrims from all faiths will take part in a religious service at their pilgrimage destination.
      </p>
      <StaticImage
        src="./blessing.png"
        className="page-right row-2"
        alt="Blessing"
      />
      <DefinitionBox title="Blessing" className="page-left">
        a request by a priest for God to take care of a particular person or a group of people.
      </DefinitionBox>

      <h2>Activities</h2>
      <ActivityBox
        title="Pilgrimage activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/Pilgrimage.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘Pilgrimage’ resource.
      </ActivityBox>
      <WhereNext
        items={[
          { name: "Medieval Pilgrims", to: "/pilgrimage/medieval-pilgrimage" },
          {
            name: "Chaucer",
            to: "/pilgrimage/chaucer",
          },
          { name: "Pilgrimage today", to: "/pilgrimage/pilgrimage-today" },
        ]}
      />
      <hr />
      <CopyrightBox />
    </Page>
  </Layout>
)

export default WhatIsPilgrimage;